<template>
  <base-section id="k-d-a-about-keda" class="white" space="0">
    <base-img :src="require('@/assets/bg/bg-white-effect-1.png')" max-height="720px">
      <v-container class="fill-height center">
        <v-row>
          <v-col
            cols="12"
            :lg="`${g_bBaseBr ? 6 : 5}`"
            :md="`${g_bBaseBr ? 6 : 5}`"
            style="flex-direction: column; display: flex; align-items: center; padding: 0px"
          >
            <div class="img-container">
              <img
                :src="require('@/assets/photos/story-00.png')"
                :class="g_bBaseBr ? 'top' : 'left'"
              />
            </div>
          </v-col>
          <v-col v-if="g_bHighestBr" lg="1" />
          <v-col v-else-if="g_bBaseBr" lg="1" />
          <v-col v-else-if="g_bHigherBr" lg="1" />
          <v-col
            cols="12"
            :lg="`${g_bBaseBr ? 5 : 6}`"
            :md="`${g_bBaseBr ? 5 : 6}`"
            :style="stylesText"
          >
            <div v-for="(item, id) in m_arrContents" :key="id">
              <component
                :is="item.strComponent"
                v-if="item.htmlText"
                :class="item.isCustomStyle ? item.eType : getTextClasses(item.eType)"
                v-html="item.htmlText"
              />
              <component
                :is="item.strComponent"
                v-else-if="item.strText"
                :class="item.isCustomStyle ? item.eType : getTextClasses(item.eType)"
              >
                {{ item.strText }}
              </component>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import TextClass from '@/mixins/text-class';
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAStoryKeDA',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  computed: {
    m_arrContents() {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.story.header.title'),
          strComponent: 'h1',
          isCustomStyle: true,
          eType: `kda-ts-${this.$vuetify.breakpoint.smAndDown ? 28 : 46}pt wt-black`
        },
        {
          htmlText: this.$vuetify.lang.t('$vuetify.kda.story.header.desc'),
          strComponent: 'h5',
          isCustomStyle: true,
          eType: `kda-ts-${this.$vuetify.breakpoint.smAndDown ? 12 : 16}pt nunito wt-regular`
        }
      ];
    },
    stylesText() {
      return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: `${this.$vuetify.breakpoint.smAndDown ? '0px 20px 30px' : '0px'}`
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.left {
      height: 460px;
      width: 460px;
      object-fit: cover;
    }

    &.top {
      left: 0;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      position: relative;
      padding: 0px;
    }
  }
}

#k-d-a-about-keda {
  z-index: 1;
}
</style>
